/* eslint-disable @next/next/no-img-element */
import { numberWithCommas } from "utilities/helper";
import { IMG_URL, API_IMG } from "utilities/constants";
import Image from 'next/image';
import Link from 'next/link';
import { isEmpty, isArray } from 'lodash';
import { countDownTimerTemp } from 'utilities/helper';
import http from "../../../utilities/http";
import { getCookie } from "../../../framework/libs/cookie";
import { TOKEN_SHOP } from "../../../framework/store/constants";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useCart } from "framework/store";
import { formatSku } from 'utilities/helper';
import dayjs from "dayjs";
import { getUrlName, getXXXPrice } from 'utilities/helper';
import isNumber from "lodash/isNumber";

const toiUuAnh = (url) => {
    if (url.indexOf('http') != 0) url = IMG_URL + url;
    if (url.indexOf('sellercenter.happynest.vn/media/') == -1) return url;
    const split = url.split('sellercenter.happynest.vn/media/');
    return 'https://staging-sellercenter.happynest.vn/media/vgs-optimiazation/vgs-mobile/' + split[1];
};

export default function Category({ item, index, isFlashSale, setOnDeleteWishList, isHorizon = false, isFuturePrice = false }) {
    const [stateStore, actions] = useCart();
    const vendorList = stateStore.vendorList;
    const thisInWishList = stateStore.wishListAll.find(x => x.sku == item.sku);

    let imgUrl = '/images/icon-app.svg';
    let freeShip = null;
    let minimalPrice = 0;
    let vendorName = '';
    let urlKey = '';
    if (item.custom_attributes) {
        const atributeImgUrl = item.custom_attributes.find(f => f.attribute_code == 'image');
        const atributeThumbnail = item.custom_attributes.find(f => f.attribute_code == 'thumbnail');

        const atributeFreeShip = item.custom_attributes.find(f => f.attribute_code == 'free_shipping');
        const atributeMinimalPrice = item.custom_attributes.find(f => f.attribute_code == 'minimal_price');
        const atributeVendor = item.custom_attributes.find(f => f.attribute_code == 'vendor_id');

        const special_price = item.custom_attributes.find(f => f.attribute_code == 'special_price');
        const special_price_to = item.custom_attributes.find(f => f.attribute_code == 'special_to_date');
        const special_price_from = item.custom_attributes.find(f => f.attribute_code == 'special_from_date');

        const url_key = item.custom_attributes.find(f => f.attribute_code == 'url_key');
        imgUrl = IMG_URL + (atributeThumbnail?.value || atributeImgUrl?.value);

        freeShip = atributeFreeShip ? atributeFreeShip.value : null;
        minimalPrice = +atributeMinimalPrice?.value || 0;
        const vendor = vendorList.find((vendor) => vendor.id == atributeVendor.value);
        vendorName = vendor?.company || vendor?.shop_name || vendor?.name || vendor?.vendor_id;
        item.special_price = dayjs(special_price_to?.value).diff(dayjs(), 'minute') >= 0 && dayjs(special_price_from?.value).diff(dayjs(), 'minute') <= 0
            ? special_price?.value
            : null;

        urlKey = url_key?.value || '';
    }
    if (imgUrl.includes('/undefined') && !isEmpty(item.media_gallery_entries)) {
        imgUrl = IMG_URL + item.media_gallery_entries[0]?.file;
    };
    if (item.thumbnail_url) {
        imgUrl = item.thumbnail_url;
    }
    if (item.image) {
        imgUrl = item.image;
    }

    if (!imgUrl.startsWith('http')) {
        imgUrl = API_IMG + '/media/' + imgUrl;
    }

    if (item.avatar_url) {
        imgUrl = item.avatar_url;
    }
    //find brand
    item.brand = !isEmpty(item.extension_attributes?.detail_information) ? item.extension_attributes.detail_information.find(x => x.code === 'brand')?.value || null : null;

    //----------------------------------Xử lý hiển thị/lưu/bỏ lưu sản phẩm---------------------------------
    const [isInWishList, setIsInWishList] = useState(stateStore.wishListAll.some(x => x.sku == item.sku));
    useEffect(() => {
        setIsInWishList(stateStore.wishListAll.some(x => x.sku == item.sku));
    }, [stateStore.wishListAll]);

    const refetchWishList = async () => {
        const reswishList = await http('/rest/V1/wishlist/allItems', {}, { token: 'Bearer ' + getCookie(TOKEN_SHOP) });
        if (isArray(reswishList)) {
            actions.setWishListAll(reswishList || []);
        }
    };

    const onDelete = async () => {
        if (isEmpty(getCookie(TOKEN_SHOP))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        if (!isInWishList) return;

        setIsInWishList(false);
        // Bỏ lưu sản phẩm
        const res = await http('/rest/V1/wishlist/' + thisInWishList.itemId, {}, {
            token: 'Bearer ' + getCookie(TOKEN_SHOP),
            method: 'DELETE'
        });
        if (res.message) toast.error(res.message);
        if (setOnDeleteWishList) setOnDeleteWishList(true);

        refetchWishList();
    };

    const onSave = async () => {
        if (isEmpty(getCookie(TOKEN_SHOP))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }

        setIsInWishList(true);
        const res = await http('/rest/V1/wishlist/', {}, {
            body: {
                sku: `${item.sku}`
            },
            token: 'Bearer ' + getCookie(TOKEN_SHOP),
            method: 'PUT'
        });

        if (res.message) toast.error(res.message);
        refetchWishList();
    };

    const sortByPriceFlashSale = (a, b) => {
        if (a.price_flash_sales - b.price_flash_sales > 0) return -1;
        if (a.price_flash_sales - b.price_flash_sales < 0) return 1;
        return 1;
    };
    const sortByPriceDealHot = (a, b) => {
        if (a.price_deal_hot - b.price_deal_hot > 0) return -1;
        if (a.price_deal_hot - b.price_deal_hot < 0) return 1;
        return 1;
    };

    item.displayPrice = item.discount_type ? item.price : (item.price || minimalPrice);
    if (!isEmpty(item.extension_attributes?.deal_hot_data)) {
        // const saleDatas = item.extension_attributes?.deal_hot_data.sort(sortByPriceDealHot);
        const saleDatas = item.extension_attributes?.deal_hot_data;
        const saleData = saleDatas[0];
        item.displayPrice = saleData.price_deal_hot;

        item.sale_value = saleData.deal_hot_discount_value;
        item.discount_type = saleData.deal_hot_discount_type;
    };

    if (!isEmpty(item.extension_attributes?.flash_sales_data)) {
        isFlashSale = true;
        // const saleDatas = item.extension_attributes?.flash_sales_data.sort(sortByPriceFlashSale);
        const saleDatas = item.extension_attributes?.flash_sales_data;
        const saleData = saleDatas[0];
        if (saleData.price_flash_sales) {
            item.displayPrice = saleData.price_flash_sales;
            item.sale_value = saleData.flash_sales_discount_value;
            item.discount_type = saleData.flash_sales_discount_type;
        };
    };

    if (item.deal_hot_discount_type) {
        item.displayPrice = item.discount_value;
        item.sale_value = item.deal_hot_discount_value;
        item.discount_type = item.deal_hot_discount_type;
    }

    if (item.flashsales_discount_type) {
        isFlashSale = true;
        item.displayPrice = item.price_discount;
        item.sale_value = item.flashsales_discount_value;
        item.discount_type = item.flashsales_discount_type;
    }

    const sourcePrice = item.discount_type ? +item.displayPrice : (+item.special_price || +item.displayPrice);
    let lastPrice = numberWithCommas(isNumber(sourcePrice) ? Math.round(sourcePrice) : sourcePrice);
    let saleAmount = '';

    const is_out_stock = item.extension_attributes?.is_in_stock === false || (item.extension_attributes?.hpn_product_option && !item.extension_attributes.hpn_product_option.some(x => x.qty > 0));

    const link = urlKey ? `/shop/${formatSku(item.sku)}/${urlKey}.html` : `/shop/${formatSku(item.sku)}/${getUrlName(item.name || item.title)}.html`;

    const isTagDealHot = !index && !isFlashSale && (item.deal_hot_discount_type || item.extension_attributes?.deal_hot_data);

    if (isFlashSale) {
        if (item.start_at) {
            isFuturePrice = (dayjs(item.start_at).unix() - dayjs().unix()) >= 0;
        }

        if (item.extension_attributes?.flash_sales_data) {
            isFuturePrice = false;
            const saleDatas = item.extension_attributes?.flash_sales_data;
            const saleData = saleDatas[0];

            const timeLeft = saleData.time_left.split(' ngày ');
            if (timeLeft[0] > 0) {
                item.end_at = dayjs().add(timeLeft[0], 'day');
            } else {
                const time = timeLeft[1].split(':');
                item.end_at = dayjs().add(time[0], 'hour').add(time[1], 'minute').add(time[2], 'second');
            }
        }

        if (isArray(item.extension_attributes?.future_price?.flashsale_price)) {
            isFuturePrice = true;
            item.discount_type = item.extension_attributes.future_price.flashsale_price[2];
            item.sale_value = item.extension_attributes.future_price.flashsale_price[3];

            if (isNumber(item.extension_attributes.future_price.flashsale_price[0])) {
                lastPrice = item.extension_attributes.future_price.flashsale_price[0];
                
            } else {
                lastPrice = item.extension_attributes.future_price.flashsale_price[1];
            }
        }
    }

    if (isArray(item.extension_attributes?.future_price?.dealhot_price)) {
        isFuturePrice = true;
        item.discount_type = item.extension_attributes.future_price.dealhot_price[2];
        item.sale_value = item.extension_attributes.future_price.dealhot_price[3];

        if (isNumber(item.extension_attributes.future_price.dealhot_price[0])) {
            lastPrice = item.extension_attributes.future_price.dealhot_price[0];
        } else {
            lastPrice = item.extension_attributes.future_price.dealhot_price[1];
        }
    }

    if (isFuturePrice) {
        lastPrice = getXXXPrice(lastPrice);
    }

    if (item.discount_type === 'by_fixed') {
        // saleAmount = !isFuturePrice ? numberWithCommas(item.sale_value) : getXXXPrice(item.sale_value);
        saleAmount = Math.round(item.sale_value * 100/(item.price || minimalPrice));
        saleAmount = saleAmount.toString();
        if (isFuturePrice) {
            saleAmount = saleAmount.length == 2 ? saleAmount[0] + 'x' : 'x';
        }
    }

    if (item.discount_type === 'by_percent') {
        saleAmount = (item.sale_value + '');
        if (isFuturePrice) {
            saleAmount = saleAmount.length == 2 ? saleAmount[0] + 'x' : 'x';
        }
    }
    let couponLabel = "";
    if (item.extension_attributes?.best_product_coupon) {
        couponLabel = isArray(item.extension_attributes.best_product_coupon) ? item.extension_attributes.best_product_coupon[0] : item.extension_attributes.best_product_coupon;
    }

    return (
        <div className={!isHorizon ? "products" : "profile-info"}>
            {isTagDealHot && <div className="dealhot-tag">
                <div>DEAL HOT</div>
                <img src="/images/Shop/TAG.svg" alt="" />
            </div>}
            <div className="products-thumb link-image-area">
                {index && <div className="prohot-rectangle" style={{ zIndex: 1 }}>{index}</div>}
                <Link href={link} passHref={true}>
                    <a>
                        <img src={imgUrl} alt={item.name || item.title} layout='fill' />
                        {/* <img src={toiUuAnh(imgUrl)} alt={item.name || item.title} layout='fill' /> */}
                        <div className="gradient-cover" style={{
                            'background': '#fff',
                            'background': 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0), rgba(90,90,90,0.4))',
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                            top: 0,
                            left: 0,
                            position: 'absolute'
                        }}></div>
                    </a>
                </Link>
                {!index && isFlashSale &&
                    <div className="flashSale-time">
                        <span>
                            {
                                !isFuturePrice
                                    ? <><img src="/images/icon-time-circle.svg" alt="" className="pc-only" />{countDownTimerTemp(item.end_at)}</>
                                    : 'Sắp diễn ra'
                            }
                        </span>
                    </div>
                }
                {is_out_stock && <div className="out-stock-info">HẾT HÀNG</div>}
                {!is_out_stock && <div className="product-layer">
                    {(item.free_text) && <img className="layer-free-text" src={item.free_text.startsWith("[{") ? JSON.parse(item.free_text)[0].url : item.free_text} />}
                    {(freeShip || item.product_free_shipping || item.product_free_shipping) && <img className="layer-free-text" height="29px" src="/images/freeship.svg" />}
                </div>}
                {isInWishList && <div className="products-bookmark" style={{ cursor: "pointer" }} onClick={() => { onDelete(); }}>
                    <Image src="/images/icon-bookmark03.svg" alt="" width={16} height={19} />
                </div>}
                {!isInWishList && <div className="products-bookmark" style={{ cursor: "pointer" }} onClick={() => { onSave(); }}>
                    <Image src="/images/icon-bookmark.svg" alt="" width={16} height={19} />
                </div>}
            </div>
            <div className="products-content">
                <p className="products-company" style={{ height: 18, overflow: "hidden" }} title={vendorName || item.shop_name || item.brand}>
                    {vendorName || item.shop_name || item.brand}
                </p>
                <div className="products-title">
                    <Link href={link}>
                        <a><h3 title={item.name || item.title}>{item.name || item.title}</h3></a>
                    </Link>
                </div>
                <div className="products-view">
                    {(item?.extension_attributes?.rating_summary > 0 || item.rating_summary > 0) && <>
                        <span className="products-number">
                            <img src="/images/icon-star.svg" alt="" style={{ marginRight: 2 }} />
                            {item?.extension_attributes?.rating_summary || item.rating_summary || ''}
                        </span>
                        <span className="products-numberItem">({item?.extension_attributes?.reviews_count || item.reviews_count} đánh giá)</span>
                    </>
                    }
                    {/* {(!item?.extension_attributes?.rating_summary && !item.rating_summary) &&
                        <span className="products-numberItem">Chưa có đánh giá</span>
                    } */}
                </div>
                {
                    // (item.discount_type || (item.special_price > 0 && item.special_price < item.price) || (minimalPrice > 0 && minimalPrice < item.price)) &&
                    <p className="products-price">
                        {(item.special_price > 0 && item.special_price < item.price && !item.discount_type && !isFuturePrice) && <span className="price-before mr-1"> đ{numberWithCommas(item.price || minimalPrice)}</span>}
                        {item.discount_type === 'by_fixed' && <span className="products-number"> -{saleAmount}%</span>}
                        {item.discount_type === 'by_percent' && <span className="products-number"> -{saleAmount}%</span>}
                        <span className="products-priceNumber" style={{ whiteSpace: 'nowrap' }}>{lastPrice <= 1 ? 'Liên hệ' : ('đ' + lastPrice)}</span>
                    </p>
                }
                {couponLabel && <div className="products-label">
                    <div className="products-label-content">{couponLabel}</div>
                </div>}
                {/* {index == 1 && (!item?.extension_attributes?.rating_summary && !item.rating_summary) && <div style={{ height: 20 }}></div>} */}
            </div>
        </div>
    );
};
